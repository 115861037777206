







































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.upload-btns {
  height: 40px;

  .el-upload {
    height: 40px !important;
    border: none !important;
  }
}

.el-textarea__inner {
  min-height: 12rem !important;
  resize: none;
}

.pageForm-btns {
  display: flex;
  align-items: center;
  justify-content: center;
}

.left {
  /deep/ .el-form-item__content {
    margin-left: 0 !important;
  }
}

.formContractId {
  .el-form-item__content {
    display: flex;
    align-items: center;

    .el-select {
      width: 100%;
    }
  }
}

.formSelect {
  .el-select {
    width: 100%;
  }
}
.form-item-inline{
  display: inline-block;
  .el-input{
    width: 100px;
  }
}
